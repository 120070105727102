import React, { ReactNode } from "react";

import { Link } from "gatsby";

interface InternalExternalLinkProps {
  to?: string;
  children?: ReactNode;
  className?: string;
  props?: object;
  tabIndex?: number;
}

const InternalExternalLink: React.FC<InternalExternalLinkProps> = ({
  to,
  children,
  className,
  ...props
}) => {
  if (!to) {
    return (
      <span className={className} {...props}>
        {children}
      </span>
    );
  }

  if (to.startsWith("http") || to.startsWith("mailto")) {
    return (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={to}
        className={className}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className} {...props}>
      {children}
    </Link>
  );
};

export { InternalExternalLink };
