import React, { useMemo, ReactNode } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Icon, iconKeys } from "../Icon";
import { InternalExternalLink } from "../InternalExternalLink";
import * as styles from "./Tag.module.scss";

interface TagProps {
  children: ReactNode;
  icon?: string;
  reverse?: boolean;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  label?: string;
  invert?: boolean;
  squared?: boolean;
  blink?: boolean;
  className?: string;
  props?: object;
}

// Remaps tag button to corresponding icon size
const iconSizes = {
  normal: "xsmall",
};

const Tag: React.FC<TagProps> = ({
  children,
  icon,
  reverse,
  size = "normal",
  onClick,
  to,
  label,
  invert,
  squared,
  blink,
  className,
  ...props
}) => {
  const cls = classNames(
    styles.tag,
    [styles[size]],
    {
      blink: blink,
      [styles.reverse]: reverse,
      [styles.invert]: invert,
    },
    className,
  );

  const tagType = useMemo(() => {
    if (to) {
      return "link";
    }

    if (onClick) {
      return "button";
    }

    return "normal";
  }, [onClick, to]);

  const innerContent = useMemo(() => {
    return (
      <>
        {icon && (
          <Icon
            className={styles.icon}
            size={iconSizes[size as keyof object]}
            name={icon}
          />
        )}
        {children}
      </>
    );
  }, [icon, children, size]);

  if (tagType === "button") {
    return (
      <button onClick={onClick} className={cls} {...props}>
        {innerContent}
      </button>
    );
  }

  if (tagType === "link" && to) {
    return (
      <InternalExternalLink
        aria-label={label}
        to={to.trim()}
        className={cls}
        {...props}
      >
        {innerContent}
      </InternalExternalLink>
    );
  }

  return (
    <span className={cls} {...props}>
      {innerContent}
    </span>
  );
};

export { Tag };
