import {
  themeInterface,
  themeObjInterface,
} from "../interfaces/projectpageInterface";

interface profileItemInterface {
  displayName?: string;
  slug?: string;
  submitted?: boolean;
}

interface memberItemInterface {
  cloudinary?: any;
  displayName?: string;
  programme?: string;
  school?: string;
  slug?: string;
}

interface membersInterface {
  edges: [
    {
      node: {
        cloudinary?: any;
        displayName?: string;
        programme?: string;
        school?: string;
        slug?: string;
      };
    },
  ];
}

// export const getProjectTeam = (
//   profiles: [profileItemInterface],
//   members: membersInterface
// ) => {
//   let arr: any[] = [];
//   let arrNoImage: any[] = [];

//   const flatMembers = members.edges.map((item) => {
//     return item.node;
//   });

//   profiles?.forEach((profile: profileItemInterface) => {
//     const member = flatMembers.find((item) => item.slug === profile.slug);

//     if (member?.cloudinary) {
//       arr.push({
//         label: profile.displayName,
//         slug: member?.slug,
//         cloudinary: member?.cloudinary,
//         programme: member?.programme,
//       });
//     } else {
//       if (profile.submitted === true) {
//         arrNoImage.push({
//           label: profile.displayName,
//           slug: member?.slug,
//           cloudinary: null,
//           programme: member?.programme,
//         });
//       }
//     }
//   });

//   arr = arr.concat(arrNoImage);

//   return arr;
// };

export const getProjectTeam = (
  profiles: [profileItemInterface],
  members: membersInterface,
  membersDetails: any,
) => {
  let arr: any[] = [];
  let arrNoImage: any[] = [];

  const flatMembersDetails = membersDetails.edges.map((item, index) => {
    return item.node;
  });

  members?.forEach((member) => {
    const memberDetails = flatMembersDetails.find(
      (item) => item.slug === member?.slug,
    );

    const profile = profiles.find((item) => item.slug === member.slug);

    if (memberDetails) {
      arr.push({
        label: member.displayName,
        slug: member?.slug,
        cloudinary: memberDetails?.cloudinary,
        programme: memberDetails?.programme,
        submitted: profile?.submitted,
      });
    } else {
      arrNoImage.push({
        label: member.displayName,
        slug: member?.slug,
        cloudinary: null,
        programme: memberDetails?.programme,
        submitted: profile?.submitted,
      });
    }
  });

  arr = arr.concat(arrNoImage);

  return arr;
};

// create a variable to store titles and slug for all project filter
export const allThemes = { slug: "all-themes", text: "all themes" };

// typescript for arrays of objects
// https://stackoverflow.com/questions/25469244/how-can-i-define-an-interface-for-an-array-of-objects
interface projectInterface {
  node: {
    subthemes: [{ slug: string; text: string }];
    themes: [{ slug: string; text: string }];
  };
}

// instead of projectInterface[] we could use Array<projectInterface> as below
// interface projectsInterface extends Array<projectInterface> {}
// instead we use projectInterface[]

// create objects to use for filtering projects
// returns object with all themes/subthemes and combinations of themes/subthemes
export const createThemeObjects = (projects: projectInterface[]) => {
  const themesObject: themeObjInterface = projects.reduce(
    (acc, project) => {
      project.node.themes.forEach((theme) => {
        if (!acc.themes.some((t: themeInterface) => t.theme === theme.slug)) {
          acc.themes.push({
            title: theme.text,
            slug: `/projects/${theme.slug}`,
            theme: theme.slug,
            subtheme: "*",
            type: "theme",
            // projects: [],
          });
        }
      });
      project.node.subthemes.forEach((subtheme) => {
        if (
          !acc.subthemes.some(
            (s: themeInterface) => s.subtheme === subtheme.slug,
          )
        ) {
          acc.subthemes.push({
            title: subtheme.text,
            slug: `/projects/${allThemes.slug}/${subtheme.slug}`,
            theme: "*",
            subtheme: subtheme.slug,
            type: "subtheme",
            // projects: [],
          });
        }
      });
      return acc;
    },
    { themes: [] as themeInterface[], subthemes: [] as themeInterface[] },
  );

  // add projects to themes/subthemes
  // themesObject.themes.forEach((theme) => {
  //   const themeProjects: projectInterface[] = projects.filter((project) => {
  //     return project.node.themes.some((t) => {
  //       return t.slug === theme.slug;
  //     });
  //   });
  //   theme.projects = themeProjects;
  // });

  // themesObject.subthemes.forEach((subtheme) => {
  //   const subthemeProjects = projects.filter((project) => {
  //     return project.node.subthemes.some((s) => {
  //       return s.slug === subtheme.slug;
  //     });
  //   });
  //   subtheme.projects = subthemeProjects;
  // });

  // create combinations of themes/subthemes
  // use this for filtered subthemes later on
  const themeCombinations = themesObject.themes.flatMap((theme) => {
    const combinations = themesObject.subthemes.map((subtheme) => {
      const filteredProjects = projects.filter((project) => {
        return (
          project.node.themes.some((t) => {
            return t.slug === theme.theme;
          }) &&
          project.node.subthemes.some((s) => {
            return s.slug === subtheme.subtheme;
          })
        );
      });
      if (filteredProjects.length > 0) {
        return {
          title: `${subtheme.title}`,
          slug: `/projects/${theme.theme}/${subtheme.subtheme}`,
          theme: theme.theme,
          subtheme: subtheme.subtheme,
          // projects: filteredProjects,
        };
      }
    });
    if (combinations.length > 0) {
      // remove empty things in the array
      return combinations.filter(Boolean);
    }
  });

  return { themesObject, themeCombinations };
};
