import React, { useCallback, useMemo } from "react"

import classNames from "classnames"
import PropTypes from "prop-types"
import ReactSelect from "react-select"

import { MediaQuery, useMediaQuery } from "../../../utils/hooks/mediaQuery"
import { Icon } from "../Icon"
import * as styles from "./Select.module.scss"

const DownIcon = ({ innerProps }) => {
  return <Icon className={styles.icon} {...innerProps} name="chevron" />
}

const RCASelect = ({
  children,
  initialLabel,
  className,
  items,
  onChange,
  value,
  formLabel = { text: "filter", id: "filter" },
  ...props
}) => {
  const isTablet = useMediaQuery(MediaQuery.Tablet)

  const longestLabel = useMemo(() => {
    return Math.max(...items.map((item) => item.label.length))
  }, [items])

  const internalOnChange = useCallback(
    (e) => {
      if (e.target) {
        onChange(e.target.value)
      } else {
        onChange(e.value)
      }
    },
    [onChange],
  )

  const selectedValue = useMemo(() => {
    return items.find((item) => item.value === value) || false
  }, [value, items])

  if (isTablet) {
    return (
      <span style={{ "--select-width": `${longestLabel}ch` }}>
        <label className="visually-hidden" htmlFor={formLabel.id}>
          {formLabel.text}
        </label>
        <ReactSelect
          inputId={formLabel.id}
          className={classNames(styles.select, className)}
          classNamePrefix="rca-select"
          placeholder={initialLabel || "Select..."}
          styles={{
            container: () => ({}),
          }}
          options={items}
          components={{
            DropdownIndicator: DownIcon,
            DownChevron: DownIcon,
          }}
          value={selectedValue}
          onChange={internalOnChange}
          {...props}
        />
      </span>
    )
  }

  return (
    <div className={classNames(styles.select, className)}>
      <select value={value} onChange={internalOnChange} {...props}>
        {initialLabel && (
          <option
            label={initialLabel}
            value={initialLabel}
            defaultValue={initialLabel}
            disabled
            hidden
          >
            {initialLabel}
          </option>
        )}
        {items.map((item, index) => (
          <option
            label={item.label}
            key={`${item.value}-${index}`}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <Icon name="chevron" size="small" className={styles.icon} />
    </div>
  )
}

RCASelect.propTypes = {
  initialLabel: PropTypes.string,
}

export { RCASelect }

// ;<div class="rca-select__menu css-26l3qy-menu" id="react-select-7-listbox">
//   <div class="rca-select__menu-list css-4ljt47-MenuList">
//     <div
//       class="rca-select__option css-yt9ioa-option"
//       aria-disabled="false"
//       id="react-select-7-option-0"
//       tabindex="-1"
//     >
//       Environments
//     </div>
//     <div
//       class="rca-select__option css-yt9ioa-option"
//       aria-disabled="false"
//       id="react-select-7-option-1"
//       tabindex="-1"
//     >
//       Deep Connections
//     </div>
//     <div
//       class="rca-select__option css-yt9ioa-option"
//       aria-disabled="false"
//       id="react-select-7-option-2"
//       tabindex="-1"
//     >
//       Bodies
//     </div>
//     <div
//       class="rca-select__option rca-select__option--is-focused css-1n7v3ny-option"
//       aria-disabled="false"
//       id="react-select-7-option-3"
//       tabindex="-1"
//     >
//       The Big Beyond
//     </div>
//   </div>
// </div>
