import React from "react";

import classNames from "classnames";
import { useRCAContext } from "../../../context/RCAContext";
import { ReactComponent as RCADarkSvg } from "../../../images/rca-logo-dark.svg";
import { ReactComponent as RCASvg } from "../../../images/rca-logo.svg";
import { wrapper } from "./RCALogo.module.scss";

interface RCALogoProps {
  className: string;
}

const RCALogo: React.FC<RCALogoProps> = ({ className }) => {
  const { colorMode } = useRCAContext();

  return (
    <div className={classNames(wrapper, className)}>
      {colorMode === "dark" ? <RCASvg /> : <RCADarkSvg />}
    </div>
  );
};

export { RCALogo };
