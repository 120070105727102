import React, { ReactNode } from "react";

import classNames from "classnames";

import { InternalExternalLink } from "../InternalExternalLink";
import * as styles from "./Button.module.scss";

interface ButtonProps {
  to?: string;
  children?: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: string;
  props?: object;
}
const Button: React.FC<ButtonProps> = ({
  to,
  children,
  className,
  ...props
}) => {
  const cls = classNames(styles.btn, className);

  if (to) {
    return (
      <InternalExternalLink className={cls} to={to} {...props}>
        {children}
      </InternalExternalLink>
    );
  }

  return (
    <button className={cls} {...props}>
      {children}
    </button>
  );
};

export { Button };
