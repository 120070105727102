import React from "react";

import { motion, AnimatePresence } from "framer-motion";

import { useRCAContext } from "../../../context/RCAContext";
import { Tag } from "../../common/Tag";
import * as styles from "./ServiceWorkerRefresh.module.scss";

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    transition: {
      delay: 1,
    },
    opacity: 1,
  },
};

const ServiceWorkerRefresh = () => {
  const { swUpdated } = useRCAContext();
  const onClick = () => window.location.reload();

  return (
    <AnimatePresence>
      {swUpdated && (
        <motion.div
          variants={variants}
          initial="initial"
          animate={"enter"}
          role="button"
          className={styles.wrapper}
          onClick={onClick}
        >
          <span className={styles.text}>
            There is a new version of the site. Please click here or refresh the
            page
          </span>
          <Tag onClick={onClick}>Reload</Tag>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { ServiceWorkerRefresh };
