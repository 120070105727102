interface themeItemInterface {
  slug: string;
}

interface programmeItemInterface {
  slug: string;
  code: string;
}

export const createAllStudentPageCombinations = (
  themes: [themeItemInterface],
  programmes: [programmeItemInterface],
) => {
  let arr = [];

  arr.push({ path: "/students", themeSlug: "*", programmeCode: "*" });

  themes.forEach((theme: themeItemInterface, index: number) =>
    arr.push({
      path: `/students/${theme.slug}`,
      themeSlug: theme.slug,
      programmeCode: "*",
    }),
  );

  programmes?.forEach((programme, index) =>
    arr.push({
      path: `/students/all-themes/${programme.slug}`,
      themeSlug: "*",
      programmeCode: programme.code,
    }),
  );

  themes.forEach((theme, index) =>
    programmes?.forEach((programme, index) =>
      arr.push({
        path: `/students/${theme.slug}/${programme.slug}`,
        themeSlug: theme.slug,
        programmeCode: programme.code,
      }),
    ),
  );

  return arr;
};
