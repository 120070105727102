import React, { useCallback } from "react";

import classNames from "classnames";

import { useRCAContext } from "../../../context/RCAContext";
import { colorModes } from "../../../utils/constants";
import * as styles from "./Mode.module.scss";

const ModeButton = ({ mode }) => {
  const { colorMode, setColorMode } = useRCAContext();

  // const currentColor = useMemo(() => {
  //   return !colorMode ? colorModes[0] : colorMode
  // }, [colorMode])

  // const nextColour = useMemo(() => {
  //   const index = colorModes.indexOf(currentColor)
  //   return colorModes[(index + 1) % colorModes.length]
  // }, [currentColor])

  const modeButtonClick = useCallback(() => {
    setColorMode(colorModes[mode]);
  }, [mode, setColorMode]);

  const currentMode = classNames({
    // [styles.lightMode]: colorMode === "light",
    [styles.greyMode]: colorMode === "grey",
    [styles.darkMode]: colorMode === "dark",
    [styles.active]: colorMode === colorModes[mode],
  });
  return (
    <button
      className={classNames(currentMode, styles.btn, [
        styles[colorModes[mode]],
      ])}
      onClick={modeButtonClick}
    >
      Aa
    </button>
  );
};

export { ModeButton };
