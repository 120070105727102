import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useLayoutEffect,
  useContext,
} from "react";

import { globalHistory } from "@reach/router";
// import { graphql, useStaticQuery } from 'gatsby';

import { detectTouched, isIOS } from "../utils/common";
import { colorModes } from "../utils/constants";

export const MENUS = {
  CLOSED: "closed",
  NAV: "nav",
  SEARCH: "search",
};

const defaultValues = {
  currentMenu: MENUS.CLOSED,
  currentMenuRef: null,
  selectedMenu: null,
  selectedTertiaryMenu: null,
  toggleMenuOpen: () => {},
  closeMenu: () => {},
  setSelectedMenu: () => {},
  setSelectedTertiaryMenu: () => {},
  isNavOpen: false,
  isSearchOpen: false,
  searchBarWidth: 0,
  isIos: false,
  swUpdated: false,
  themes: [],
  subjects: [],
  colorMode: colorModes[0],
  setColorMode: () => false,
  setChatId: () => false,
  themesVisited: [],
  setThemesVisited: () => {},
};

export const RCAContext = createContext(defaultValues);

const RCAProvider = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState(MENUS.CLOSED);
  const currentMenuRef = useRef(currentMenu);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedTertiaryMenu, setSelectedTertiaryMenu] = useState(null);
  const [searchBarWidth, setSearchBarWidth] = useState(0);
  const [bodyLocked, setBodyLocked] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [searchQuery, setSearchQuery] = useState(``);
  const [isTouched, setIsTouched] = useState(false);
  const [swUpdated, setSwUpdated] = useState(false);
  const [colorMode, setColorMode] = useState(colorModes[0]);
  const [themesVisited, setThemesVisited] = useState([]);

  const isIos = useRef(false);

  const isNavOpen = useMemo(() => {
    return currentMenu === MENUS.NAV;
  }, [currentMenu]);

  const isSearchOpen = useMemo(() => {
    return currentMenu === MENUS.SEARCH;
  }, [currentMenu]);

  const toggleMenuOpen = useCallback(
    (type = MENUS.NAV) => {
      setCurrentMenu(currentMenu === type ? MENUS.CLOSED : type);
      currentMenuRef.current = currentMenu === type ? MENUS.CLOSED : type;
      if (currentMenu === type || type === MENUS.CLOSED) setSelectedMenu(null);
    },
    [currentMenu],
  );

  const closeMenu = useCallback(() => {
    setCurrentMenu(MENUS.CLOSED);
  }, []);

  useEffect(() => {
    const historyUnlisten = globalHistory.listen(() => {
      setCurrentMenu(MENUS.CLOSED);
    });

    const touchUnlisten = detectTouched((touch) => {
      setIsTouched(touch);
    });

    isIos.current = isIOS();

    window.addEventListener("sw-updated", () => {
      setSwUpdated(true);
    });

    return () => {
      historyUnlisten();
      touchUnlisten();
    };
  }, []);

  useEffect(() => {
    if (!isTouched && !hideHeader) {
      // check if touch device
      if (isIos.current) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setHideHeader(true);
            window.scrollTo(0, window.scrollY - window.innerHeight);
          });
        });
      } else {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setHideHeader(true);
          });
        });
      }
    }
  }, [isTouched, hideHeader]);

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    setSearchBarWidth(window.innerWidth - document.documentElement.clientWidth);
  }, []);

  useEffect(() => {
    document.body.style.setProperty(
      "--search-bar-width",
      `${searchBarWidth}px`,
    );
  }, [searchBarWidth]);

  useEffect(() => {
    colorModes.forEach((cm) => {
      document.documentElement.classList.toggle(`${cm}-mode`, cm === colorMode);
    });
  }, [colorMode]);

  const setChatId = useCallback((userId) => {
    window.dispatchEvent(
      new CustomEvent("chat-id", {
        detail: {
          id: userId,
        },
      }),
    );
  }, []);

  return (
    <RCAContext.Provider
      value={{
        currentMenu,
        currentMenuRef,
        selectedMenu,
        selectedTertiaryMenu,
        toggleMenuOpen,
        closeMenu,
        setSelectedMenu,
        setSelectedTertiaryMenu,
        isNavOpen,
        isSearchOpen,
        bodyLocked,
        setBodyLocked,
        searchQuery,
        setSearchQuery,
        hideHeader,
        setHideHeader,
        isIos: isIos.current,
        swUpdated,
        // themes: themesArray,
        // subjects: subjectsArray,
        colorMode,
        setColorMode,
        setChatId,
        themesVisited,
        setThemesVisited,
      }}
    >
      {children}
    </RCAContext.Provider>
  );
};

export const useRCAContext = () => useContext(RCAContext);
export { RCAProvider };
