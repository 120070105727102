import { useEffect, useState } from "react";

export const useMediaQuery = (mqs, initial = false) => {
  const [matches, setMatches] = useState(initial);

  useEffect(() => {
    const mq = window.matchMedia(mqs);
    const cb = (e) => {
      setMatches(e.matches);
    };
    setMatches(mq.matches);

    mq.addListener(cb);

    return () => mq.removeListener(cb);
  }, [mqs]);

  return matches;
};

export const MediaQuery = {
  LargeMobile: "(min-width: 35em)",
  Tablet: "(min-width: 48em)",
  Desktop: "(min-width: 60em)",
  Widescreen: "(min-width: 75em)",
};
