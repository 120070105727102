import React from "react";

import classNames from "classnames";

import { Icon, iconKeys } from "../Icon";
import * as styles from "./RotateIcon.module.scss";

interface RotateIconProps {
  name: string;
  rotate?: number;
  startRotate?: number;
  iconProps?: object;
  toggle?: boolean;
}

const RotateIcon: React.FC<RotateIconProps> = ({
  name,
  rotate = 45,
  startRotate = 0,
  iconProps = {},
  toggle = false,
}) => {
  return (
    <span
      className={classNames(styles.wrapper, { [styles.toggled]: toggle })}
      style={{ "--rotate-total": rotate, "--rotate-start": startRotate }}
    >
      <Icon name={name} {...iconProps} />
    </span>
  );
};

export { RotateIcon };
