exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-projects-all-subthemes-tsx": () => import("./../../../src/templates/projectsAllSubthemes.tsx" /* webpackChunkName: "component---src-templates-projects-all-subthemes-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-student-tsx": () => import("./../../../src/templates/student.tsx" /* webpackChunkName: "component---src-templates-student-tsx" */),
  "component---src-templates-students-all-tsx": () => import("./../../../src/templates/studentsAll.tsx" /* webpackChunkName: "component---src-templates-students-all-tsx" */),
  "component---src-templates-students-theme-tsx": () => import("./../../../src/templates/studentsTheme.tsx" /* webpackChunkName: "component---src-templates-students-theme-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

