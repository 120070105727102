import React, { useState } from "react";

import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

import { animation } from "../../../utils/animation";
import * as styles from "./Menu.module.scss";

const variants = {
  open: { y: 0 },
  closed: { y: "-100%" },
  transition: {
    type: animation.menu.transition.type,
    stiffness: animation.menu.transition.stiffness,
    damping: animation.menu.transition.damping,
  },
};

const MenuPanel = ({ keyName, children, open }) => {
  const [animating, setAnimating] = useState(false);

  return (
    <AnimatePresence onExitComplete={() => setAnimating(false)}>
      {open && (
        <motion.div
          key={keyName}
          initial={"closed"}
          animate="open"
          exit="closed"
          transition={variants.transition}
          onAnimationComplete={() => setAnimating(false)}
          onAnimationStart={() => setAnimating(true)}
          variants={variants}
          className={classNames(styles.panel)}
        >
          {children(animating)}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

MenuPanel.propTypes = {
  children: PropTypes.func,
  keyName: PropTypes.string,
  open: PropTypes.bool,
};

export { MenuPanel };
