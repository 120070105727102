import React, { ReactNode } from "react";

import classNames from "classnames";

import { Button } from "../Button";
import { Icon } from "../Icon";
import * as styles from "./IconButton.module.scss";

interface IconButtonProps {
  icon: string;
  className?: string;
  iconProps?: object;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  props?: object;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  className,
  iconProps = {},
  onClick,
  children,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      className={classNames(styles.btn, className)}
      {...props}
    >
      {children}
      <Icon name={icon} {...iconProps} />
    </Button>
  );
};

export { IconButton };
