import React, { ReactNode, ElementType } from "react";

import classNames from "classnames";

import {
  grid,
  gap as gapCls,
  padded as paddedCls,
  paddedX as paddedXCls,
  paddedY as paddedYCls,
  paddedTlr as paddedTlrCls,
  paddedLr as paddedLrCls,
} from "./StandardGrid.module.scss";

interface StandardGridProps {
  tagName?: ElementType;
  className?: string;
  total?: number;
  mobileTotal?: number;
  desktopTotal?: number;
  gap?: boolean;
  padded?: boolean;
  paddedTLR?: boolean;
  paddedLR?: boolean;
  paddedX?: boolean;
  paddedY?: boolean;
  children?: ReactNode;
  props?: object;
}

const StandardGrid: React.FC<StandardGridProps> = ({
  tagName: El = "div",
  className,
  total = 2,
  mobileTotal = 1,
  desktopTotal,
  gap = false,
  padded = false,
  paddedTLR = false,
  paddedLR = false,
  paddedX = false,
  paddedY = false,
  children,
  ...props
}) => {
  return (
    <El
      className={classNames(
        grid,
        {
          [gapCls]: gap,
          [paddedCls]: padded,
          [paddedTlrCls]: paddedTLR,
          [paddedLrCls]: paddedLR,
          [paddedXCls]: paddedX,
          [paddedYCls]: paddedY,
        },
        className,
      )}
      style={{
        "--standard-grid-mobile-total": mobileTotal,
        "--standard-grid-total": total,
        "--standard-grid-desktop-total": desktopTotal || total,
      }}
      {...props}
    >
      {children}
    </El>
  );
};

export { StandardGrid };
