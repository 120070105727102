import React from "react";

import { Layout } from "./../../components/Layout";
// import { OptionsProvider } from './../../components/OptionsContext'

const CustomLayout = ({ element, props }, pluginOptions) => {
  return <Layout {...props}>{element}</Layout>;
};

export default CustomLayout;
