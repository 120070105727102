/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import smoothscroll from "smoothscroll-polyfill";
import "./src/fonts/index.css";
import "./src/styles/global.scss";

import { scrollDelay } from "./src/gatsby/browser/shouldUpdateScroll";
import CustomLayout from "./src/gatsby/browser/wrapPageElement";
import RCAContext from "./src/gatsby/browser/wrapRootElement";

export const wrapPageElement = CustomLayout;
export const wrapRootElement = RCAContext;
export const shouldUpdateScroll = scrollDelay;

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};

export const onClientEntry = () => {
  smoothscroll.polyfill();
};

export const onServiceWorkerUpdateReady = () => {
  setTimeout(() => {
    window.dispatchEvent(new Event("sw-updated"));
  }, 1000);
};
