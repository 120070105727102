import algoliasearch from "algoliasearch";

import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from "./constants";

// add the correct index names here
export const searchIndices = [
  // { name: `collections`, title: `Collections` },
  // { name: `articles`, title: `Articles` },
  // { name: `events`, title: `Events` },
  // { name: `programmes`, title: `Programmes` },
  { name: `students`, title: `Students` },
  { name: `projects`, title: `Projects` },
];

export const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);
