import React, { useState, useMemo, useEffect } from "react";
import classNames from "classnames";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { ListItem } from "../../common/ListItem";
import { Button } from "../../common/Button";
import { allThemes, createThemeObjects } from "../../../utils/project";
import { createAllStudentPageCombinations } from "../../../utils/studentslisting";
import { RCASelect } from "../../common/Select";

import * as styles from "./StudentsFilter.module.scss";

interface programmeInterface {
  label: string;
  code: string;
  slug: string;
}

interface themeInterface {
  slug: string;
  label: string;
  path: string;
}

interface StudentsFilterProps {
  location: { pathname: string };
  pageContext: { programmes: [{ label: string; slug: string; code: string }] };
}

const StudentsFilter: React.FC<StudentsFilterProps> = ({
  location,
  pageContext,
}) => {
  const data = useStaticQuery(graphql`
    query StudentsThemesPage {
      themes: allSanityTheme {
        edges {
          node {
            slug {
              current
            }
            themeName
          }
        }
      }
    }
  `);

  const programmes = pageContext?.programmes;
  const projects = data.projects;

  const themes = data.themes.edges.flatMap(({ node }) => {
    return {
      slug: node.slug.current,
      label: node.themeName,
      path: `/students/${node.slug.current}`,
    };
  });

  const orderedThemes = useMemo(() => {
    return themes.sort((a, b) => {
      if ((a.sortLabel || a.label) < (b.sortLabel || b.label)) {
        return -1;
      }
      if ((a.sortLabel || a.label) > (b.sortLabel || b.label)) {
        return 1;
      }
      return 0;
    });
  }, [themes]);

  const mobileThemes = useMemo(() => {
    let arr = [...orderedThemes];

    arr.unshift({
      slug: ".",
      label: "All Themes",
      path: "/students",
    });

    return arr;
  }, [orderedThemes]);

  const [currTheme, setCurrTheme] = useState("");
  const [currProgramme, setCurrProgramme] = useState("");

  // now change these states when the url changes
  useEffect(() => {
    const pathArray = location.pathname.split("/").filter(Boolean);

    setCurrTheme(pathArray.length > 1 ? pathArray[1] : allThemes.slug);
    setCurrProgramme(pathArray[2] ? pathArray[2] : "");
  }, [location.pathname]);

  const studentListingPagesCombinations = createAllStudentPageCombinations(
    themes,
    programmes,
  );

  // if we don't need this filter component return empty fragment
  const pathname = location.pathname.endsWith("/")
    ? location.pathname.slice(0, -1)
    : location.pathname;

  const handleProgrammeChange = (value: string) => {
    let slug;

    if (currTheme === "all-themes" && value === "") {
      slug = `/students`;
    } else {
      slug = `/students/${currTheme !== "" ? currTheme : "all-themes"}/${
        value !== "" && value !== "all-students" ? value : ""
      }`;
    }

    navigate(slug);
  };

  const handleMobileThemeChange = (value: string) => {
    // find the theme object that matches the value
    const themeObject = mobileThemes.find((item) => item?.slug === value);

    if (currProgramme === "") {
      navigate(`/students/${themeObject.slug}`);
    } else {
      navigate(`/students/${themeObject.slug}/${currProgramme}`);
    }
  };

  const isStudentsPath =
    studentListingPagesCombinations.some((item) => item?.path === pathname) ||
    pathname === "/students";

  if (!isStudentsPath) {
    return <></>;
  }

  return (
    <div>
      <ListItem
        topBorder={false}
        leftBorder={false}
        rightBorder={false}
        size="large"
        tagName="h1"
      >
        Students
      </ListItem>
      <div className={styles.projectsThemes}>
        <nav>
          <ul>
            <li className={classNames(styles.label)}>Filter by Theme</li>
            <ul className={styles.mobileThemes}>
              <RCASelect
                tabIndex="0"
                initialLabel="Select a theme"
                onChange={handleMobileThemeChange}
                onKeyDown={handleMobileThemeChange}
                value={currTheme || ""}
                items={mobileThemes.map((projectTheme: themeInterface) => ({
                  value: projectTheme.slug,
                  label: projectTheme.label,
                }))}
                formLabel={{
                  id: "filter-student-themes",
                  text: "filter students by theme",
                }}
              />
            </ul>
            <ul className={styles.themeList}>
              {orderedThemes.map((theme: themeInterface, index: number) => {
                return (
                  <li key={index}>
                    <Button
                      to={`/students/${theme.slug}/${
                        currProgramme !== "none" ? currProgramme : ""
                      }`}
                      className={classNames(styles.themeButton, {
                        [styles.active]: currTheme === theme.slug,
                        [styles.caring]: theme?.slug === "caring-society",
                        [styles.digital]: theme?.slug === "being-digital",
                        [styles.climate]: theme?.slug === "climate-crisis",
                        [styles.justice]:
                          theme?.slug === "justice-equality-and-misinformation",
                      })}
                    >
                      {theme.label}
                    </Button>
                  </li>
                );
              })}
              <li>
                <Button
                  to={`/students/${
                    currProgramme !== `` ? `all-themes/${currProgramme}` : ``
                  }`}
                  className={classNames({
                    [styles.active]: currTheme === allThemes.slug,
                  })}
                >
                  All Themes
                </Button>
              </li>
            </ul>
            <li className={classNames(styles.label)}>Filter by Programme</li>
            <ul className={styles.programmesSelect}>
              <RCASelect
                tabIndex="0"
                initialLabel="Select a programme"
                onChange={handleProgrammeChange}
                onKeyDown={handleProgrammeChange}
                value={currProgramme || ""}
                items={programmes?.map((programme: programmeInterface) => ({
                  value: programme.slug,
                  label: programme.label,
                }))}
                formLabel={{
                  id: "filter-projects-programmes",
                  text: "filter projects by programmes",
                }}
              />
            </ul>
            <Button
              className={classNames(styles.reset)}
              onClick={() => navigate("/projects")}
            >
              Reset
            </Button>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export { StudentsFilter };
